import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {borderRadius, prettyScroll} from "@pg-design/helpers-css";

export const MultiLeadWrapper = styled.div<{isScrollable?: boolean; prettyScrollStyle?: boolean}>`
    ${({isScrollable, prettyScrollStyle}) => css`
        ${borderRadius(2)};
        background-color: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: ${isScrollable ? "auto" : "hidden"};
        ${prettyScrollStyle ? prettyScroll() : ""};
    `};
`;
