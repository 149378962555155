import {ILeadSummaryItem} from "../../../lead/types/ILeadSummaryItem";
import {IAdditionalData} from "../../reducers/application_reducer";
import {IApplicationOffer} from "../../types/IApplicationOffer";
import {IApplicationVendor} from "../../types/IApplicationVendor";

interface IParams {
    offer: IAdditionalData<IApplicationOffer> | null;
    vendor: IAdditionalData<IApplicationVendor> | null;
    isPriceRevealed?: boolean;
}

export function transformLeadDataOfferIntoLeadSummaryItem(params: IParams) {
    const {offer: offerData, vendor: vendorData, isPriceRevealed} = params;
    const offer = offerData?.detail;
    const vendor = vendorData?.detail;

    if (!offer || !vendor) {
        return null;
    }

    return {
        mainImage: {
            desktop: offer.main_image.m_img_375x211,
            mobile: offer.main_image.m_img_375x211
        },
        offerType: offer.type,
        offerName: offer.name,
        region: offer.region,
        offerVendorName: vendor.name,
        offerAddress: offer.address,
        isPriceRevealed
    } as Omit<ILeadSummaryItem, "regionEstimatedPriceSummary">;
}
