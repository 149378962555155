import React from "react";
import {css} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";

import {ILeadSummaryItem} from "../../../../../lead/types/ILeadSummaryItem";
import {IRegionEstimatedPriceSummary} from "../../../../../region/hooks/use_region_estimated_price";
import {MultiLeadApplicationSummaryBox} from "./MultiLeadApplicationSummaryBox";

interface IProps {
    applications: Omit<ILeadSummaryItem, "regionEstimatedPriceSummary">[];
    regionEstimatedPriceSummary: IRegionEstimatedPriceSummary;
    className?: string;
}

export const MultiLeadApplicationSummary = (props: IProps) => {
    return (
        <div css={wrapperStyle} className={props.className}>
            {props.applications.map((application, i) => {
                return (
                    <MultiLeadApplicationSummaryBox key={i} {...application} regionEstimatedPriceSummary={props.regionEstimatedPriceSummary} css={boxStyle} />
                );
            })}
        </div>
    );
};

const wrapperStyle = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(1)};
    overflow: auto;
`;

const boxStyle = css`
    flex: 0 0 auto;
`;
