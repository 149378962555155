import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {FormikForm, handleFormikPost, IFormikSubmitFn} from "@pg-design/formik-utils";
import {calculateRemSize, flex, flexDirection, m, mb, mr, onDesktop, p, pb, pr, w100} from "@pg-design/helpers-css";
import {CloseIcon, InfoIcon} from "@pg-design/icons";
import {Link} from "@pg-design/link";
import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text";

import {hitMultileadSendButton} from "../../../../lead/tracking/hit_multilead_send_button";
import {resetRecommendedOffers} from "../../../../offer/detail/actions/fetch_recommended_offers";
import {resetRecommendedProperties} from "../../../../property/actions/fetch_recommended_properties";
import {useRegionEstimatedPrice} from "../../../../region/hooks/use_region_estimated_price";
import {ViewType} from "../../../../view_type/ViewType";
import {RecommendedOfferApplicationsType} from "../../../actions/track_recommended_offers_application";
import {applicationFormValidationSchema} from "../../../constants/application_form";
import {useEmailVerification} from "../../../hooks/use_email_verification";
import {useEstimatedPriceByArea} from "../../../hooks/use_estimated_price_by_area";
import {useMultiLeadData} from "../../../hooks/use_multi_lead_data";
import {useMultiLeadFormSend} from "../../../hooks/use_multi_lead_form_send";
import {useRecommendationsSelection} from "../../../hooks/use_recommendations_selection";
import {IApplication} from "../../../types/IApplication";
import {IApplicationFormValues} from "../../../types/IApplicationFormValues";
import {MultiLeadRecommendationType} from "../../../types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../../../types/recommended_property_applications_type";
import {ApplicationSourceSection} from "../../../utils/ApplicationSource";
import {report} from "../../../utils/recommended_application/analytics_events";
import {shouldApplicationPriceBeenShown} from "../../../utils/should_application_price_been_shown";
import {ApplicationOfferRevealedPriceBox} from "../../application_form/application_offer/offer_box/ApplicationOfferRevealedPriceBox";
import {ApplicationUserDataFormFields} from "../common/ApplicationUserDataFormFields";
import {RecommendedList} from "./parts/RecommendedList";

// Constant values
// TODO: Bring back if we'll need to set some defaults again - CU-8695y5kux
// const MAX_SHOWN_CURLED = 3;

export interface IMultiLeadSimpleRecommendationsModalProps {
    sourceSection: ApplicationSourceSection;
    onModalClose: () => void;
    onSuccessfulSubmit?: (applications: RecommendedPropertyApplicationsType[] | RecommendedOfferApplicationsType[]) => void;
    storeHash: string;
    recommendationType: MultiLeadRecommendationType;
    originalApplicationFormValues: IApplicationFormValues;
    recommendationsWithPriceFilter: boolean;
    customSourceSection?: string;
    originalApplicationResponse: IApplication | null;
}

// TODO: Bring back if we'll need to set some defaults again - CU-8695y5kux
// const calcMaxRecommendations = (recommendations: IRecommendedProperty[], originApplicationRegionCityID: number | undefined) => {
//     const maxRecommendationDistance =
//         originApplicationRegionCityID && originApplicationRegionCityID === WARSAW_REGION_ID
//             ? MAX_DISTANCE_FROM_ORIGINAL_OFFER_KM_WARSAW
//             : MAX_DISTANCE_FROM_ORIGINAL_OFFER_KM_ALL_REGIONS;
//
//     const baseRecommendationsGroupAmount = recommendations.filter((recommendation) => recommendation.distance < maxRecommendationDistance).length;
//
//     return baseRecommendationsGroupAmount > 0 && baseRecommendationsGroupAmount < MAX_SHOWN_CURLED ? baseRecommendationsGroupAmount : MAX_SHOWN_CURLED;
// };

export const MultiLeadSimpleRecommendationsModal = (props: IMultiLeadSimpleRecommendationsModalProps) => {
    const dispatch = useDispatch();
    const {
        viewType,
        // TODO: Bring back if we'll need to set some defaults again - CU-8695y5kux
        // originalApplicationRegionCityID,
        originalApplicationGeoPoint,
        recommendedProperties,
        recommendedOffers,
        originalApplicationCountry,
        applicationData
    } = useMultiLeadData(props);
    const {regionEstimatedPriceSummary, requestState: regionEstimatedPriceSummaryRequestState} = useRegionEstimatedPrice({
        region: applicationData?.offer?.detail?.region
    });
    const {estimatedPriceInfoText} = useEstimatedPriceByArea({
        regionEstimatedPriceSummary,
        price: props.originalApplicationResponse?.property_price,
        isPriceRevealed: shouldApplicationPriceBeenShown(props.originalApplicationResponse),
        area: applicationData?.property?.detail?.area,
        country: originalApplicationCountry
    });

    const [showPropertiesList, setShowPropertiesList] = useState(true);
    const [isMultiLeadSubmitting, setIsMultiLeadSubmitting] = useState(false);

    // TODO: Bring back if we'll need to set some defaults again - CU-8695y5kux
    // const maxRecommendationsShownOnCurl = calcMaxRecommendations(recommendedProperties, originalApplicationRegionCityID);

    const {selectedRecommendationsCount, selectedRecommendations, toggleRecommendation} = useRecommendationsSelection({
        recommendedOffers,
        recommendedProperties,
        // TODO: Bring back if we'll need to set some defaults again - CU-8695y5kux
        //maxRecommendationsShown: maxRecommendationsShownOnCurl,
        maxRecommendationsShown: 0,
        recommendationType: props.recommendationType
    });

    const isSendApplicationDisabled = selectedRecommendationsCount === 0;

    useEffect(() => {
        recommendedOffers.slice(3).map((el) => toggleRecommendation(el.id));
        return () => {
            // Clear recommended properties, offers and form values - we don't need this data anymore
            dispatch(resetRecommendedProperties());
            dispatch(resetRecommendedOffers());
        };
    }, []);

    const {sendMultiLeadForm} = useMultiLeadFormSend(props);
    const {props: emailNotificationProps, validateEmailOnce} = useEmailVerification();

    const onSubmit: IFormikSubmitFn<IApplicationFormValues> = async (formValues, formikHelpers) => {
        if (formValues?.email && !(await validateEmailOnce(formValues?.email))) {
            return;
        }

        setIsMultiLeadSubmitting(true);

        handleFormikPost(
            sendMultiLeadForm({
                selectedRecommendations,
                formValues
            }),
            formikHelpers,
            {
                onSuccess: (sentRecommendedLeads) => {
                    props.onSuccessfulSubmit && props.onSuccessfulSubmit(sentRecommendedLeads);
                },
                onFieldError: () => setIsMultiLeadSubmitting(false),
                onNonFieldError: () => setIsMultiLeadSubmitting(false)
            }
        );
    };

    const handleChange = (viewType: ViewType, recommendationId: number) => {
        const isCheckboxSelected = toggleRecommendation(recommendationId);
        // GTM & Algolytics events
        report.checkboxChange(props.recommendationType, viewType, isCheckboxSelected, recommendationId);
    };

    const handlePinClick = (viewType: ViewType | null, recommendationId: number) => {
        if (!(props.recommendationType in MultiLeadRecommendationType)) {
            throw new Error("Unknown recommendation type");
        }

        const isCheckboxSelected = toggleRecommendation(recommendationId);
        report.mapPinClick(viewType, recommendationId, isCheckboxSelected);
    };

    const onSendClick = () => {
        hitMultileadSendButton();
    };

    const isSingleLeadSent = Boolean(props.originalApplicationResponse);
    const isLeadPropertyAvailable = Boolean(applicationData?.property?.detail?.id);

    return (
        <div css={holder}>
            <FormikForm onSubmit={onSubmit} initialValues={props.originalApplicationFormValues} validationSchema={applicationFormValidationSchema}>
                {() => {
                    return (
                        <div css={innerFormStyle}>
                            <div css={headerSectionStyle}>
                                <Text variant="headline_6" css={[m(0, 4, 1, 0)]}>
                                    Odkryj ceny i zapytaj o podobne oferty w najbliższej okolicy.
                                </Text>

                                <div css={topPanelWrapper}>
                                    <Link underline onClick={() => setShowPropertiesList((prevState) => !prevState)}>
                                        {showPropertiesList ? "Zmień dane zapytania" : "Wybierz dodatkowe oferty"}
                                    </Link>
                                </div>
                            </div>
                            <div css={listSectionStyle}>
                                {showPropertiesList ? (
                                    <RecommendedList
                                        recommendationType={props.recommendationType}
                                        recommended={{
                                            properties: recommendedProperties,
                                            offers: recommendedOffers
                                        }}
                                        originalApplicationGeoPoint={originalApplicationGeoPoint}
                                        originalApplicationCountry={originalApplicationCountry}
                                        originalApplicationEstimatedPrice={!applicationData?.property?.detail?.id ? null : estimatedPriceInfoText}
                                        viewType={viewType}
                                        selected={selectedRecommendations}
                                        handleChange={handleChange}
                                        handlePinClick={handlePinClick}
                                        listTopSlot={
                                            isSingleLeadSent && isLeadPropertyAvailable ? (
                                                <ApplicationOfferRevealedPriceBox
                                                    offerType={applicationData?.offer?.detail?.type || 0}
                                                    offerAddress={applicationData?.offer?.detail?.address || ""}
                                                    offerMainImg={applicationData?.offer?.detail?.main_image.m_img_375x211 || ""}
                                                    rooms={applicationData?.property?.detail?.rooms}
                                                    area={applicationData?.property?.detail?.area}
                                                    estimatedPriceInfoText={estimatedPriceInfoText}
                                                    regionEstimatedPriceRequestState={regionEstimatedPriceSummaryRequestState}
                                                    css={[mr(0), mb(2)]}
                                                />
                                            ) : null
                                        }
                                    />
                                ) : (
                                    <div css={[p(1.5, 3), onDesktop(p(2, 3))]}>
                                        <ApplicationUserDataFormFields emailNotificationProps={emailNotificationProps} />
                                    </div>
                                )}
                            </div>

                            <div css={revealPriceBottomPanelStyle}>
                                <div css={revealPriceBottomPanelLeftColumnStyle}>
                                    <Popover
                                        arrow
                                        isActive={isSendApplicationDisabled}
                                        popoverPlace="top"
                                        body="Zaznacz propozycje powyżej aby wysłać zapytanie"
                                        toggleHoverHandlers
                                        toggleOnClickOutside
                                        toggleClickHandler
                                    >
                                        <SubmitButton
                                            variant="filled_primary"
                                            disabled={isSendApplicationDisabled || isMultiLeadSubmitting}
                                            isLoading={isMultiLeadSubmitting}
                                            onClick={onSendClick}
                                        >
                                            ODKRYJ CENY
                                        </SubmitButton>
                                    </Popover>
                                </div>
                                <div css={revealPriceBottomPanelRightColumnStyle}>
                                    <div css={[flex(), flexDirection("row")]}>
                                        <div css={revealPriceIconWrapperStyle}>
                                            <InfoIcon size="2.4" />
                                        </div>
                                        <Text color="#000" variant="info_txt_1">
                                            W ciągu kilku sekund otrzymasz estymowaną cenę tej nieruchomości, a w przeciągu 24 godzin deweloper skontaktuje się
                                            z Tobą i przedstawi szczegóły oferty.
                                        </Text>
                                    </div>
                                </div>
                            </div>

                            <div css={closeHolder} onClick={props.onModalClose}>
                                <CloseIcon size="2.4" />
                            </div>
                        </div>
                    );
                }}
            </FormikForm>
        </div>
    );
};

const holder = (theme: Theme) => css`
    position: relative;
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 100%;
    }
`;

const innerFormStyle = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
`;

const headerSectionStyle = css`
    ${p(1.5, 3, 0, 3)};

    ${onDesktop(p(2, 3, 0, 3))};
`;

const listSectionStyle = (theme: Theme) => css`
    display: flex;
    overflow-y: auto;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        flex: 0 0 450px;
    }
`;

const topPanelWrapper = css`
    ${pb(3)};
    ${flex("normal", "space-between")};
`;

const closeHolder = css`
    cursor: pointer;
    position: absolute;
    top: 2rem;
    right: 2.4rem;
`;

const SubmitButton = styled(FormikForm.Button)`
    width: 100%;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        min-width: 20rem;
    }
`;

const revealPriceBottomPanelStyle = (theme: Theme) => css`
    ${p(3, 1.5)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    ${w100};
    gap: ${calculateRemSize(3)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${p(2, 3)};
        flex-direction: row;
        gap: ${calculateRemSize(2)};
    }

    ${onDesktop(css`
        align-items: flex-start;
    `)}
`;

const revealPriceBottomPanelLeftColumnStyle = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.sm}) {
        flex: 0 0 460px;
    }
`;

const revealPriceBottomPanelRightColumnStyle = css`
    width: 100%;
`;

const revealPriceIconWrapperStyle = css`
    ${pr(1)};
    ${flex("center", "center")};
    flex: 0 0 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
`;
