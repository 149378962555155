import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button";
import {borderRadius, calculateRemSize, flexAbsoluteCenter, mb, mh, mt, onDesktop, p, pl, pointer, pr, pt, textAlign} from "@pg-design/helpers-css";
import {ArrowIcon, CloseIcon, InfoIcon, MapIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {useUserDevice} from "@pg-mono/user-device";

import {IRPStore} from "../../../app/rp_reducer";
import {MultiLeadWrapper} from "../../../lead/components/MultiLeadWrapper";
import {useRegionEstimatedPrice} from "../../../region/hooks/use_region_estimated_price";
import {RecommendedOfferApplicationsType} from "../../actions/track_recommended_offers_application";
import {MULTI_LEAD_DISTANCES_IN_KM} from "../../constants/multi_lead_constants";
import {useApplicationForm} from "../../hooks/use_application_form";
import {useMultiLeadData} from "../../hooks/use_multi_lead_data";
import {useMultiLeadFormSend} from "../../hooks/use_multi_lead_form_send";
import {useRecommendations} from "../../hooks/use_recommendations";
import {IPriceFilter, useRecommendationsFilters} from "../../hooks/use_recommendations_filters";
import {useTransformApplicationToMultiLead} from "../../hooks/use_transform_application_to_multi_lead";
import {IApplication} from "../../types/IApplication";
import {IApplicationFormValues} from "../../types/IApplicationFormValues";
import {IMultileadSummaryStepState} from "../../types/IMultileadSummaryStepState";
import {ISetMultiLeadSummaryStepPayload} from "../../types/ISetMultiLeadSummaryStepPayload";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../../types/recommended_property_applications_type";
import {ApplicationSourceSection} from "../../utils/ApplicationSource";
import {report} from "../../utils/recommended_application/analytics_events";
import {isRecommendationRecommendedProperty} from "../../utils/recommended_application/recommendation_type_guards";
import {
    transformRecommendedOfferIntoMultiLeadData,
    transformRecommendedPropertyIntoMultiLeadData
} from "../../utils/recommended_application/transform_recommended_into_multi_lead_data";
import {shouldApplicationPriceBeenShown} from "../../utils/should_application_price_been_shown";
import {MultiLeadMap} from "./common/MultiLeadMap";
import {MultiLeadSimpleRecommendationsModal} from "./multi_lead_simple_recommendations/MultiLeadSimpleRecommendationsModal";
import {MultiLeadCard} from "./parts/multi_lead_card/MultiLeadCard";

interface IProps {
    originalApplicationResponse: IApplication | null;
    sourceSection: ApplicationSourceSection;
    storeHash: string;
    recommendationType: MultiLeadRecommendationType;
    originalApplicationFormValues: IApplicationFormValues;
    recommendationsWithPriceFilter: boolean;
    vendorId: number;
    offerId?: number;
    propertyId?: number;
    className?: string;
    customSourceSection?: string;
    handleModalClose: (payload?: ISetMultiLeadSummaryStepPayload) => void;
    handleSetMultiLeadSummaryStep: (payload: IMultileadSummaryStepState) => void;
}

const DEFAULT_PRICE_LIMIT = {
    lower: undefined,
    upper: 1000000
};

export const MultiLead = (props: IProps) => {
    const {isMobile} = useUserDevice();
    const {priceFilter, setPriceFilter, distanceFilterInKm, setDistanceFilterInKm, canDistanceBeIncreased} = useRecommendationsFilters({
        defaultPriceLimit: DEFAULT_PRICE_LIMIT,
        recommendationType: props.recommendationType
    });
    const {viewType, originalApplicationGeoPoint, originalApplicationCountry, isAbroadLead: isAbroadOffer} = useMultiLeadData(props);
    const originalApplication = useSelector((store: IRPStore) => store.application[props.storeHash]);
    const multiLeadProperty = originalApplication?.property?.detail;
    const {applications} = useTransformApplicationToMultiLead({
        storeHash: props.storeHash,
        originalApplicationResponse: props.originalApplicationResponse,
        originalApplication,
        multiLeadProperty
    });

    const {
        areRecommendationsFetching,
        isRecommendationListEmpty,
        recommendations,
        recommendationsSelection: {selectedRecommendations},
        refreshRecommendations,
        rejectRecommendation
    } = useRecommendations({
        storeHash: props.storeHash,
        recommendationType: props.recommendationType,
        price: priceFilter,
        distance: distanceFilterInKm
    });
    const {applicationUserData} = useApplicationForm(props);
    const {isMultiLeadSending, sendMultiLeadForm} = useMultiLeadFormSend(props);
    const {regionEstimatedPriceSummary} = useRegionEstimatedPrice({
        region: originalApplication?.offer?.detail?.region
    });
    const [isMapViewOnMobile, setMapViewOnMobile] = useState(false);
    const [hasBudgetBeenChanged, setHasBudgetBeenChanged] = useState(false);

    useEffect(() => {
        report.modalOpen(props.recommendationType, recommendations);
    }, []);

    useEffect(() => {
        // reset map view on mobile
        setMapViewOnMobile(false);
    }, [isMobile]);

    useEffect(() => {
        // auto increase distance filter if there are no recommendations
        if (!areRecommendationsFetching && recommendations.length === 0 && distanceFilterInKm < MULTI_LEAD_DISTANCES_IN_KM[1]) {
            setDistanceFilterInKm(MULTI_LEAD_DISTANCES_IN_KM[1]);
            refreshRecommendations();
        }
    }, [areRecommendationsFetching, recommendations, distanceFilterInKm, setDistanceFilterInKm]);

    useEffect(() => {
        if (isRecommendationListEmpty && !canDistanceBeIncreased) {
            props.handleSetMultiLeadSummaryStep({
                multiLeadSummaryItems: applications,
                multiLeadProperty
            });
        }
    }, [isRecommendationListEmpty, canDistanceBeIncreased]);

    const onBudgetChange = (priceLimit: IPriceFilter) => {
        setPriceFilter(priceLimit);
        setHasBudgetBeenChanged(true);

        refreshRecommendations();
    };

    const onShowNextCard = () => {
        refreshRecommendations();
    };

    const onAskForPriceForSingleProperty = async (propertyId: number) => {
        await sendMultiLeadForm({
            selectedRecommendations: [propertyId],
            formValues: applicationUserData
        });
    };

    const onOfferReject = (propertyId: number) => {
        rejectRecommendation(propertyId);
    };

    const onMapButtonClick = () => {
        setMapViewOnMobile(true);
    };

    const onMapBackBtnClick = () => {
        setMapViewOnMobile(false);
    };

    const onSimpleVariantSuccessfulSubmit = (applications: RecommendedPropertyApplicationsType[] | RecommendedOfferApplicationsType[]) => {
        props.handleSetMultiLeadSummaryStep({
            multiLeadSummaryItems: applications.map(([application, appliedRecommendation]) => {
                if (isRecommendationRecommendedProperty(appliedRecommendation)) {
                    return transformRecommendedPropertyIntoMultiLeadData(appliedRecommendation, {
                        isPriceRevealed: shouldApplicationPriceBeenShown(application)
                    });
                }

                return transformRecommendedOfferIntoMultiLeadData(appliedRecommendation);
            }),
            multiLeadProperty
        });
    };

    const currentRecommendation = recommendations[0];
    const currentApplication = useMemo(() => {
        return currentRecommendation?.id
            ? applications.find((application) => {
                  return application.id.toString().includes(`p${currentRecommendation.id}`);
              })
            : null;
    }, [currentRecommendation?.id, applications]);
    const hasApplicationBeenSentForCurrentRecommendation = !!currentApplication;

    const onCloseClick = () => {
        props.handleModalClose();
    };

    return (
        <MultiLeadWrapper isScrollable={Boolean(isMobile)}>
            {!isAbroadOffer ? (
                <MultiLeadSimpleRecommendationsModal
                    originalApplicationFormValues={props.originalApplicationFormValues}
                    recommendationsWithPriceFilter={props.recommendationsWithPriceFilter}
                    storeHash={props.storeHash}
                    sourceSection={props.sourceSection}
                    onModalClose={onCloseClick}
                    onSuccessfulSubmit={onSimpleVariantSuccessfulSubmit}
                    recommendationType={props.recommendationType}
                    customSourceSection={props.customSourceSection}
                    originalApplicationResponse={props.originalApplicationResponse}
                />
            ) : (
                <>
                    <div css={[headerStyle, isMapViewOnMobile ? p(0, 1.5) : ""]}>
                        {isMapViewOnMobile ? (
                            <Button iconLeft={ArrowIcon} variant="none_secondary" onClick={onMapBackBtnClick}>
                                Powróć
                            </Button>
                        ) : (
                            <>
                                <div css={closeHolderStyle} onClick={onCloseClick}>
                                    <CloseIcon wrapperSize="2.4" wrapperColor="#fff" size="2.4" css={[pointer]} />
                                </div>
                                <Text variant="headline_5" css={[onDesktop(textAlign("center")), pr(6), onDesktop(pl(6)), mb(1)]}>
                                    Przejrzyj i zapytaj o podobne oferty w najbliższej okolicy.
                                </Text>
                                <Text variant="info_txt_1" css={onDesktop(textAlign("center"))}>
                                    Przyspiesz swoje poszukiwania i skorzystaj z rekomendowanych ofert, dobranych na podstawie Twoich preferencji.
                                </Text>
                            </>
                        )}
                    </div>
                    <div css={offerColumnStyle}>
                        {originalApplicationGeoPoint && originalApplicationCountry ? (
                            <>
                                <MultiLeadMap
                                    key={`MultiLeadMap_${isMobile ? "mobile" : "desktop"}`}
                                    disableCircleAroundMarkers
                                    hoveredItemID={null}
                                    originalGeoPoint={currentRecommendation}
                                    originalCountry={originalApplicationCountry}
                                    viewType={viewType}
                                    boundsPaddingTopLeft={isMobile ? undefined : [550, 0]}
                                    boundsPaddingBottomRight={isMobile ? [0, 150] : undefined}
                                    additionalCircleRadiusInMeters={distanceFilterInKm * 1000}
                                    disableRadiusCalcByMarkers
                                    recommendations={[]}
                                    recommendationType={props.recommendationType}
                                    checkedList={selectedRecommendations}
                                />
                            </>
                        ) : null}
                        <OfferOverlay isOverlayFullHeight={!isMapViewOnMobile}>
                            {currentRecommendation ? (
                                <>
                                    <MultiLeadCard
                                        {...currentRecommendation}
                                        price={currentRecommendation.price || currentApplication?.price}
                                        isPriceRevealed={currentApplication?.isPriceRevealed}
                                        recommendationsCount={recommendations.length}
                                        compareAreaTo={applications[0]?.area}
                                        hasApplicationBeenSent={hasApplicationBeenSentForCurrentRecommendation}
                                        hideGallery={!!(isMobile && isMapViewOnMobile)}
                                        hideCardStockEffect={!!(isMobile && isMapViewOnMobile)}
                                        regionEstimatedPriceSummary={regionEstimatedPriceSummary}
                                        areRecommendationsFetching={areRecommendationsFetching}
                                        isMultiLeadSending={isMultiLeadSending}
                                        onBudgetChange={onBudgetChange}
                                        hasBudgetBeenChanged={hasBudgetBeenChanged}
                                        onAskForDetails={onAskForPriceForSingleProperty}
                                        onOfferReject={onOfferReject}
                                        onShowNextCard={onShowNextCard}
                                    >
                                        {isMobile && !isMapViewOnMobile ? (
                                            <Button variant="none_secondary" size="small" iconLeft={MapIcon} onClick={onMapButtonClick} css={mapButtonStyle}>
                                                Zobacz na mapie
                                            </Button>
                                        ) : null}
                                    </MultiLeadCard>
                                    {(isMobile && isMapViewOnMobile) || hasApplicationBeenSentForCurrentRecommendation || isAbroadOffer ? null : (
                                        <Text as="div" variant="info_txt_1" css={[radiusInfoWrapperStyle, mt(2), isMobile ? mb(2) : ""]}>
                                            <div css={radiusInfoIconWrapper}>
                                                <InfoIcon size="2" wrapperSize="2" />
                                            </div>
                                            Oferty w promieniu {distanceFilterInKm}km
                                        </Text>
                                    )}
                                </>
                            ) : null}
                        </OfferOverlay>
                    </div>
                </>
            )}
        </MultiLeadWrapper>
    );
};

const closeHolderStyle = css`
    position: absolute;
    right: ${calculateRemSize(3)};
    top: ${calculateRemSize(3)};
    z-index: 10000;
`;

const headerStyle = css`
    ${p(3, 1.5, 2)};
    flex: 0 0;
`;

const offerColumnStyle = css`
    position: relative;
    flex: 1 1 auto;

    ${onDesktop(css`
        height: 60rem;
    `)};
`;

const OfferOverlay = styled.div<{isOverlayFullHeight: boolean}>`
    position: absolute;
    inset: 0;
    top: ${({isOverlayFullHeight}) => (isOverlayFullHeight ? 0 : "unset")};
    ${pt(3)};
    padding-bottom: ${({isOverlayFullHeight}) => (isOverlayFullHeight ? 0 : calculateRemSize(2))};
    background-color: ${({isOverlayFullHeight}) => (isOverlayFullHeight ? "#fff" : "transparent")};
    overflow: auto;

    @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        display: block;
        right: auto;
        background-color: transparent;
        width: 55rem;
        padding-top: 4rem;
        padding-left: 8rem;
    }
`;
const radiusInfoWrapperStyle = (theme: Theme) => css`
    display: flex;
    ${borderRadius(1)};
    ${p(1)};
    ${mh("auto")};
    width: fit-content;
    background-color: #fff;
    color: ${theme.colors.gray[800]};
`;

const radiusInfoIconWrapper = css`
    ${flexAbsoluteCenter};
    width: 2.4rem;
`;

const mapButtonStyle = css`
    ${mt(1.5)};
    ${mb(2)};
    width: 100%;
    height: 4rem;
`;
