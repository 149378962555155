import React, {useCallback} from "react";
import {css, Theme} from "@emotion/react";

import {OSMGenericNearbyOffersMap} from "../../../../../maps/components/generic_nearby_offers/open_street_maps/OSMGenericNearbyOffers";
import {IRecommendedOffer} from "../../../../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../../../../recommendations/types/IRecommendedProperty";
import {Country} from "../../../../../region/types/Country";
import {ViewType} from "../../../../../view_type/ViewType";
import {MultiLeadRecommendationType} from "../../../../types/MultiLeadRecommendationType";
import {getRecommendedMapIcon} from "../../../../utils/recommended_application/get_recommended_map_icon";
import {
    transformRecommendedOfferIntoMapOffer,
    transformRecommendedPropertyIntoMapOffer
} from "../../../../utils/recommended_application/transform_recommended_into_map_offer";

const multiLeadMainOfferPin = require("../../../../../maps/images/pins/multi_lead_main_offer_pin.svg");

interface IProps {
    checkedList: number[];
    className?: string;
    viewType: ViewType | null;
    originalGeoPoint: {
        coordinates: [number, number];
    };
    originalCountry: Country;
    originalOfferLabel?: string | null;
    recommendations: IRecommendedOffer[] | IRecommendedProperty[];
    handlePinClick: (viewType: ViewType | null, recommendedItemID: number) => void;
    hoveredItemID: number | null;
    recommendationType: MultiLeadRecommendationType;
}

const RecommendedMapC: React.FC<IProps> = (props) => {
    const {
        originalGeoPoint: {coordinates: originCoordinates},
        originalCountry
    } = props;

    const getMarkerIcon = (offerId: number): string => {
        const isChecked = props.checkedList.includes(offerId);
        return getRecommendedMapIcon(isChecked);
    };

    const handleDesktopInfoWindowOpen = (recommendedItemID: number) => {
        props.handlePinClick(props.viewType, recommendedItemID);
    };

    const getOffersData = () => {
        if (props.recommendationType === MultiLeadRecommendationType.PROPERTY) {
            return props.recommendations.map((recommendation) => transformRecommendedPropertyIntoMapOffer(recommendation as IRecommendedProperty));
        }

        return props.recommendations.map((recommendation) => transformRecommendedOfferIntoMapOffer(recommendation as IRecommendedOffer));
    };

    const getMarkerLabel = useCallback(() => "?", []);

    return (
        <div css={recommendedMapWrapper} className={props.className}>
            <OSMGenericNearbyOffersMap
                drawCircleAroundMarkers
                viewType={props.viewType}
                hoveredItemID={props.hoveredItemID}
                originalMarkerCoordinates={originCoordinates}
                offers={getOffersData()}
                getMarkerIcon={getMarkerIcon}
                getMarkerLabel={getMarkerLabel}
                originalMarkerLabel={props.originalOfferLabel}
                originalMarkerIcon={multiLeadMainOfferPin}
                originalMarkerCountry={originalCountry}
                onDesktopInfoWindowOpen={handleDesktopInfoWindowOpen}
            />
        </div>
    );
};

export const RecommendedMap = React.memo(RecommendedMapC);

const recommendedMapWrapper = (theme: Theme) => css`
    position: relative;
    padding-top: 100%;

    @media (min-width: ${theme.breakpoints.sm}) {
        padding-top: 0;
        height: 100%;
    }

    @media (max-height: 840px) and (max-width: ${theme.breakpoints.sm}) {
        padding-top: 35vh;

        .lazy-google-map-container {
            max-height: 35vh;
        }
    }
`;
