import React, {useState} from "react";

import {IRecommendedOffer} from "../../../../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../../../../recommendations/types/IRecommendedProperty";
import {Country} from "../../../../../region/types/Country";
import {ViewType} from "../../../../../view_type/ViewType";
import {MultiLeadRecommendationType} from "../../../../types/MultiLeadRecommendationType";
import {RecommendedOfferList} from "./RecommendedOfferList";
import {RecommendedPropertyList} from "./RecommendedPropertyList";

interface IRecommendedListProps {
    recommendationType: MultiLeadRecommendationType;
    originalApplicationGeoPoint:
        | {
              coordinates: [number, number];
          }
        | undefined
        | null;
    originalApplicationCountry?: Country;
    recommended: {
        properties: IRecommendedProperty[];
        offers: IRecommendedOffer[];
    };
    viewType: ViewType | null;
    selected: number[];
    handleChange: (viewType: ViewType, recommendedItemID: number) => void;
    handlePinClick: (viewType: ViewType | null, recommendedItemID: number) => void;
    listTopSlot?: React.ReactNode;
    originalApplicationEstimatedPrice?: string | null;
}

export const RecommendedList = (props: IRecommendedListProps) => {
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

    const onListScroll = (event: React.UIEvent) => {
        const scrollTop = (event.target as HTMLElement).scrollTop;

        if (scrollTop > 0) {
            return setIsScrolledToBottom(true);
        }

        setIsScrolledToBottom(false);
    };

    switch (props.recommendationType) {
        // Note: `offer` recommendations can be shown only if they are delivered by backend.
        // If you want to see some, select rooms before sending application
        case MultiLeadRecommendationType.OFFER: {
            return (
                <RecommendedOfferList
                    recommendedOffers={props.recommended.offers}
                    isScrolledToBottom={isScrolledToBottom}
                    onListScroll={onListScroll}
                    selected={props.selected}
                    handleChange={props.handleChange}
                />
            );
        }
        case MultiLeadRecommendationType.PROPERTY: {
            return (
                <RecommendedPropertyList
                    offers={props.recommended.properties}
                    selectedOffers={props.selected}
                    isScrolledToBottom={isScrolledToBottom}
                    handleChange={props.handleChange}
                    handlePinClick={props.handlePinClick}
                    onListScroll={onListScroll}
                    originalApplicationGeoPoint={props.originalApplicationGeoPoint}
                    originalApplicationCountry={props.originalApplicationCountry}
                    listTopSlot={props.listTopSlot}
                    originalApplicationEstimatedPrice={props.originalApplicationEstimatedPrice}
                />
            );
        }
        default: {
            return null;
        }
    }
};
