import {useState} from "react";

export interface IAppError {
    status: number;
    fieldErrors: Record<string, string[]>;
    nonFieldErrors: string[];
}

export enum RequestState {
    None,
    Waiting,
    Success,
    Error
}

type FetchActionTypes = "start" | "success" | "error" | "reset";

export const fetchRequestState = (requestType: FetchActionTypes): RequestState => {
    switch (requestType) {
        case "start":
            return RequestState.Waiting;
        case "success":
            return RequestState.Success;
        case "error":
            return RequestState.Error;
        case "reset":
        default:
            return RequestState.None;
    }
};

type UseFetchReturnType<ReturnType> = {
    fetchStart: () => Promise<ReturnType>;
    response: ReturnType | null;
    fetchState: RequestState;
    error: IAppError | null;
    fetchReset: () => void;
};

export const useFetch = <ReturnType>(promise: () => Promise<ReturnType>): UseFetchReturnType<ReturnType> => {
    const [fetchState, setFetchState] = useState(RequestState.None);
    const [response, setResponse] = useState<ReturnType | null>(null);
    const [error, setError] = useState<IAppError | null>(null);

    const fetchStart = (): Promise<ReturnType> => {
        setFetchState(fetchRequestState("start"));
        return promise()
            .then((res) => {
                setFetchState(fetchRequestState("success"));

                if (res !== null) {
                    setResponse(res);
                }
                return res;
            })
            .catch(async (error: IAppError) => {
                setFetchState(fetchRequestState("error"));
                setError(error);
                return Promise.reject(error);
            });
    };
    const fetchReset = () => {
        setFetchState(RequestState.None);
        setResponse(null);
        setError(null);
    };

    return {fetchStart, response, fetchState, error, fetchReset};
};
